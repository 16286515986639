html {
    overflow: hidden;
    overflow-y: scroll;
}
body {
    font-family: 'Poppins', sans-serif !important;
    overflow: hidden;
    font-size: 12px;
    padding: 0px;
    margin: 0px; 
    position: relative;
    /* background-image: url('./images/aaa/website.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; */
    height: auto;
}
.no-padd {
    padding: 0px;
}
.m-zero {
    padding: 0px;
}

.show-loader {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000000e6;
    z-index: 9999;
}