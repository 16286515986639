@import "../../_theme/variable.scss";

.privacy-tab {
    .nav-item {
        margin: 10px 0;
        font-size: 12px;
        a {
            background: none;
            border: 1px solid #70707021 !important;
            border-radius: 8px;
            color: $content;
            &.nav-link.active {
                background-image: linear-gradient(to right, #ef5ca0, #fe5b88, #ff616d, #ff6d52, #fd7d36);
                box-shadow: 2px 4px 25px #FE670369;
                border-radius: 8px;
                opacity: 1;
            }
        }
    }
    .head-medium-two {
        font-weight: 700;
    }
}

@media only screen and (min-width: 1550px)  {  
    .privacy-tab .nav-item a.nav-link {
        padding: 18px 20px !important;
        font-size: 16px !important;
    }
    .privacy-tab {
        .head-medium-two {
            font-size: 20px !important;
        }
    }
}