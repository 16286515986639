.kiosk-three {
    margin-bottom: 5rem;
    .ultra {
        img{
            width: 110px;
        }
        h4 { 
            height: 20px; 
            margin: 10px 0;
        }
        span {
            min-height: 45px;
            display: block;
        }
    }

    .mach-block {
            background-image: url('../../images/Ellipse-small.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 40%;
            img{
                width: 25%;
            }
    }
    .field-mention {
        .item-one {
                position: absolute;
                top: 7rem;
                left: 6rem;
                img {
                    position: absolute;
                    width: 70%;
                    left: 13rem;
                    top: 0;
                }
            }
            .item-two {
                position: absolute;
                top: 15rem;
                left: 4rem;
                img {
                    position: absolute;
                    width: 80%;
                    left: 13rem;
                    top: -1rem;
                }
            }
            .item-three {
                position: absolute;
                top: 12rem;
                left: 4rem;
                img {
                    position: absolute;
                    width: 80%;
                    left: 13rem;
                    top: -1rem;
                }
            }
            .item-four {
                position: absolute;
                top: 18rem;
                left: 5rem;
                img {
                    position: absolute;
                    width: 80%;
                    left: 13rem;
                    top: -2rem;                
                }
            }
            .item-five {
                position: absolute;
                top: 8rem;
                right: 3rem;           
                img {
                    position: absolute;
                    width: 75%;
                    right: 14rem;
                    top: -1rem;
                }
            }
            .item-six {
                position: absolute;
                top: 14rem;
                right: 2rem;          
                img {
                    position: absolute;
                    width: 80%;
                    right: 13rem;
                    top: 0rem;
                }
            }
            .item-seven {
                position: absolute;
                top: 15rem;
                right: 2rem;         
                img {
                    position: absolute;
                    width: 80%;
                    right: 13rem;
                    top: 0rem;
                }
            }
            .item-eight {
                position: absolute;
                top: 20rem;
                right: 3rem;        
                img {
                    position: absolute;
                    width: 80%;
                    right: 13rem;
                    top: -1rem;
                }
            }

        .card-item {
            border: 1px solid whitesmoke;
            padding: 10px 10px;
            border-radius: 8px;
            width: 210px;
            min-height: 85px;
            h5 {
                font-size: 13px !important;
                font-weight: 600 !important;
            }
            p.content {
                font-size: 12px !important;
                font-family: 'poppins',regular !important;
                line-height: 21px !important;
            }
        }
    }
}


/* Hd Media Query */
@media only screen and (min-width: 1550px)  { 
    .kiosk .ultra h4 {
        font-size: 19px !important;
    }
    .kiosk-three {
        .field-mention { 
            .item-one {
                img {
                    left: 18rem;
                }
            }
            .item-three {
                top: 16rem;
                img {
                    left: 18rem;;
                }
            }
            .item-four {
                top: 25rem;
                img {
                    left: 18rem;;
                }
            }
            .item-five {
                top: 25rem;
                img {
                    right: 18rem;
                }
            }
            .item-seven {
                top: 15rem;
                img {
                    right: 18rem;;
                }
            }
            .item-eight {
                top: 8rem;
                img {
                    right: 18rem;;
                }
            }
            .card-item {
                width: 290px !important;
                padding: 15px 15px !important;
                min-height: 100px;
                h5 {
                    font-size: 20px !important;
                }
                p.content {
                    font-size: 16px !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 996px)  {
    .field-mention {
        display: none;
    }
}