
@keyframes left-to-right {
    0%, 100% {
      left: 0;
    }
  
    50% {
      left: 10px;
    }
}

@keyframes lineanim {
    0%, 100% {
      left: 0;
    }
  
    50% {
      left: 100px;
    }
}

@keyframes oscil {
    from {transform: rotate(0deg); animation-timing-function: cubic-bezier(0.1, 0.3, 0.3, 1);}
    25% {transform: rotate(5deg); animation-timing-function: cubic-bezier(0.7, 0.0, 0.9, 0.7);}
    50% {transform: rotate(0deg); animation-timing-function: cubic-bezier(0.1, 0.3, 0.3, 1);}
    75% {transform: rotate(-5deg); animation-timing-function: cubic-bezier(0.7, 0.0, 0.9, 0.7);}
    to {transform: rotate(0deg);}
  }

  
@keyframes oscil2 {
  from {transform: rotate(0deg); animation-timing-function: cubic-bezier(0.1, 0.3, 0.3, 1);}
  25% {transform: rotate(15deg); animation-timing-function: cubic-bezier(0.7, 0.0, 0.9, 0.7);}
  50% {transform: rotate(0deg); animation-timing-function: cubic-bezier(0.1, 0.3, 0.3, 1);}
  75% {transform: rotate(-15deg); animation-timing-function: cubic-bezier(0.7, 0.0, 0.9, 0.7);}
  to {transform: rotate(0deg);}
}

  @keyframes bounce-1 {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-100px); }
    100% { transform: translateY(0); }
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}


@keyframes pulseanim {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 248, 225, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 248, 225, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

@keyframes pulseanimbig {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 248, 225, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 248, 225, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 60px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}