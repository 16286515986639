@import "../../_theme/variable.scss";
@import "../../_theme/flex.scss";
@import "../../_theme/variable.scss";
@import "../../style.scss";

.header-block {
  .logo-img {
    img {
      width: 133px;
      height: auto;
    }
  }
  .navbar {
    padding: 30px 0px;
  }
  .navbar-collapse {
    @include justify-content(flex-end !important);
    .navbar-nav {
      @include align-items(center !important);
    }
  }
  .menu a {
    font-size: 12px;
    padding: 5px 15px;
    color: $white;
    display: flex;
    align-items: center;
    &:hover {
      outline: none;
      text-decoration: none;
    }
  }
  .otherpage {
    background: $white !important;
    .menu a {
      color: $content;
      &.active {
        position: relative;
        display: grid;
        font-weight: 600;
        /* &:after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    display: block;
                    margin: 0 auto;
                    background: $gradient-color;
                    bottom: 0;
                    border-radius: 14px;
                } */
      }
    }
  }
}


.footer-main {
  margin-top: 10rem;
  .ip-field {
    display: block;
    width: 60%;
    margin: 40px auto;
    input {
      background: #f4f5f642 0% 0% no-repeat padding-box;
      border: 1px solid #70707030;
      border-radius: 5px !important;
      opacity: 1;
      font-size: 12px;
      padding: 20px 15px;
    }
  }
  .send-btn {
    position: absolute;
    height: 41px;
    width: 40px;
    top: 0;
    right: 10px;
    .btn {
      height: 100%;
      width: 100%;
      cursor: pointer;
    }
    img {
      position: absolute;
      display: block;
      width: 15px;
      top: 12px;
    }
  }
}

.footmenu a.active {
  position: relative;
  &:after {
    position: absolute;
    height: 4px !important;
    border-radius: 15px !important;
    bottom: -6px !important;
    z-index: 1 !important;
  }
}

span.common-item {
  font-size: 12px;
  a {
    color: $content;
  }
}
.footmenu span.head-medium-two {
  display: inline-block;
  width: 33%;
  font-weight: 500;
  a {
    color: $content;
  }
  a.active {
    position: relative;
    display: inline;
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 12px;
      left: 0;
      border-radius: 10px;
      background-image: linear-gradient(
        to right,
        #ec3b8d,
        #fc3773,
        #ff4057,
        #ff5137,
        #fe6703
      );
      bottom: 2px;
      z-index: -1;
    }
  }
}

.confirmPage {
  margin: 100px;
  height: 280px;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 33px 39px rgba(0,0,0,.06);
}

.confirmButton {
  font-size: 14px !important;
  margin-top: 30px;
}

.icon-feeling{
  height: 20%;
}


.news-message {
  text-align: center;
  padding: 20px;
}

.news-letter {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 33px 39px #0000000f;
  border-radius: 13px;
  opacity: 1;
  min-height: 185px;
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: -5rem;
  h4 {
    font-weight: 600;
    font-size: 27px;
    color: #313f4c;
  }
}

.menu-section {
  margin: 14rem 0 4rem 0;
}

.foot-1 {
  position: absolute;
  height: auto;
  width: 27%;
  left: -15rem;
  top: -2rem;
  animation: oscil 6s infinite;
}
.foot-2 {
  position: absolute;
  height: auto;
  width: 27%;
  left: -16rem;
  top: 0rem;
  animation: oscil 5s infinite;
}
.foot-3 {
  position: absolute;
  height: auto;
  width: 25%;
  right: -12rem;
  top: 0rem;
  animation: oscil 6s infinite;
}
.foot-4 {
  position: absolute;
  height: auto;
  width: 21%;
  right: -10rem;
  top: 0rem;
  animation: oscil 6s infinite;
}

.footlogo {
  margin: 0px 0 25px 0;
  width: 160px;
  height: auto;
}

.icon-block {
  @include flexbox();
  @include justify-content(flex-end);
  @include align-items(center);
}
ul.socialmedia {
  display: block;
  li {
    display: inline-block;
    margin: 0 0px 0 15px;
    &:nth-child(1) {
      img {
        height: auto;
        width: 10px;
      }
    }
    &:nth-child(2) {
      img {
        height: auto;
        width: 19px;
      }
    }
    &:nth-child(3) {
      img {
        width: 19px;
        height: auto;
      }
    }
    &:nth-child(4) {
      img {
        width: 20px;
        height: auto;
      }
    }
    &:nth-child(5) {
      img {
        width: 19px;
        height: auto;
      }
    }
  }
}

.head-wrap-er {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  .logo {
    img {
      width: 180px;
      height: auto;
    }
  }
  .menuitems {
    z-index: 9;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: flex-start;
}
}


@media only screen and (min-width: 1550px) {
  .header-block {
    .menu a {
      font-size: 16px;
    }
    .logo-img img {
      width: 215px;
    }
  }
  .menu-section { 
    margin-top: 20rem !important; 
    margin-bottom: 4rem !important;
    .head-medium-two, p.content-hash {
      font-size: 16px !important;
    }
  }
  span.common-item {
    font-size: 16px !important;
  }
  .footer-main .send-btn img {
    width: 25px;
    top: 20px;
    right: 15px;
}
}

/**
768
*/
@media only screen and (min-width: 320px) and (max-width: 1200px)  {
 
  .menu.navbar-nav { margin-bottom: 10px; }
  .social-block { margin-top: 25px !important; margin-bottom: 0px !important;}
  .foot-end {
    flex-flow: wrap-reverse;
    .text-center { margin: 5px 0; }
  }
}

.coming-soon-head{
  color:#ff5a22
}
.coming-soon-text{
  font-size: 16px;
}
