@import "../../_theme/variable.scss";
@import "../../_theme/flex.scss";

h5.head-no-bold {
  font-weight: normal;
}
h4.head-bold {
  font-weight: 600;
}
.vision-block {
  padding: 0 3rem;
}

.vision-content {
  margin-left: 25px;
  margin-top: -45px;
}

.quotes {
  font-size: 60px;
  color: #ee0a77;
}

.timeline {
  background-image: url("../../images/milestone-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 1.5rem 0 1.5rem 0;

  .milestone-card {
    background: #fff;
    height: 300px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    padding: 30px;
    .img-row {
      @include flexbox();
      @include justify-content(flex-start);
      @include align-items(center);
      margin: 10px 0;
      img {
        width: 75px;
        height: auto;
        margin: 0 15px 0 0;
      }
      h5.curryear {
        font-size: 33px;
        font-weight: 700;
        color: #000;
      }
    }
    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 25px;
      background-image: linear-gradient(
        to top,
        #ec3b8d,
        #fc3773,
        #ff4057,
        #ff5137,
        #fe6703
      );
      z-index: 9;
      display: block;
      margin: auto 0;
      left: -5px;
      top: 0px;
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
    }
  }
}

.milestone-wrapper {
  .main-head {
    h2 {
      opacity: 0.19;
      color: #fff;
      text-transform: uppercase;
      font-size: 4em;
      font-weight: 600;
    }
    span {
      position: absolute;
      top: 27px;
      left: 30px;
      font-size: 16px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

@media only screen and (min-width: 1550px) {
  .about-us {
    h5.head-no-bold,
    .head-bold {
      font-size: 30px;
    }
  }
  .milestone-wrapper .main-head span {
    top: 23px;
    font-size: 22px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 540px) {
  .values-block {
    padding: 2rem;
  }
  .milestone-wrapper .timeline-logo {
    display: block;
    margin: auto !important;
    width: 100% !important;
    img {
      display: block;
      margin: auto;
    }
  }
  .timeline .milestone-card {
    padding: 30px 30px 30px 60px !important;
    border-bottom-left-radius: 20px !important;
    border-top-left-radius: 20px !important;
    .curryear {
      text-align: center;
      font-weight: 700;
      font-size: 25px;
    }
    &::after {
      left: 15px;
      border-bottom-left-radius: 20px !important;
      border-top-left-radius: 20px !important;
    }
  }
}
.mgt-10{
  margin-top: 10%;
}
