@import "./_theme/flex.scss";
@import "./_theme/variable.scss";
@import "./_theme/_anim.scss";


// Gradient Mixins
@mixin mbme-gradient() {
    background-image: linear-gradient(to right, #ec3b8d, #fc3773, #ff4057, #ff5137, #fe6703);
}
a:focus {
    outline: none;
}
a:hover {
    text-decoration: none;
}
.field-padd { padding: 4rem 0; }
.field-padd-3 { padding: 3rem 0; }
.field-padd-2 { padding: 2rem 0; }
.field-padd-1 { padding: 1rem 0; }

.c-pointer {
    cursor: pointer;
}
h4 {
    p {
        font-weight: bold;
        color: $heading-bold;
        margin: 0px;
        padding: 0px;
        line-height: 43px;
        font-size: 30px;
    }
}
h4.head-medium {
    opacity: 1;
    font-size: 16px;
    margin: 15px 0px 5px 0px
}
.head-medium-two {
    opacity: 1;
    font-size: 12px;
    margin: 15px 0px 5px 0px
}

.head-medium-block {
    opacity: 1;
    font-size: 14px;
    margin: 15px 0px 5px 0px;
    font-weight: 800;
    text-transform: uppercase;
}
.head-medium-normal {
    opacity: 1;
    font-size: 13px;
    margin: 15px 0px 5px 0px;
    font-weight: 700;
}

.u-case {
    text-transform: uppercase;
}

p.content, .content {
    font-size: 12px !important;
    font-family: 'poppins',regular  !important;
    line-height: 21px  !important;
}

p.content-hash {
    font-size: 11px;
    font-family: 'poppins',regular;
    color: $content;
    opacity: 0.5;
    line-height: 21px;
}
.p-bold {
    font-size: 12px;
    font-weight: 700;
    margin: 10px 0 0 0;
    min-height: 10px;
}

.grecaptcha-badge { visibility: hidden; }

.btn.focus, .btn:focus { box-shadow: none; }

.p-600 {
    font-weight: 600;
}
.gray {
    color : #949292  !important;
}
.font-normal {
    font-weight: 300 !important;
}

.gra-uline {
    display: inline-block;
    &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 12px;
        left: 0;
        border-radius: 10px;
        background-image: linear-gradient(to right, #ec3b8d, #fc3773, #ff4057, #ff5137, #fe6703);
        bottom: 2px;
        z-index: -1;
    }
}
.space-letter {
    font-size: 12px;
    letter-spacing: 13px;
    line-height: 0;
    text-transform: uppercase;
}

.footer-main {
    background-color: $light-gray;
}


.img-with-data {
    .move-item {
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 13px;
        color: $white;
    }
}

span.error {
    font-size: 11px;
    color: red;
    padding-left: 10px;
}

.popup {
    transform: translate(-50%, -50%);
    position: fixed;
    left: 50%;
    top: 50%;
    color: #fff;
    height: 45%;
    width: 44%;
    background: white;
    z-index: 999;
    box-shadow: -3px 0px 16px #5C5C5C14;
    border-radius: 3px;
    transition: all 0.1s;
    img {
        height: 60px;
        width: 60px;
    }
    .pop-inner {
        transform: translate(-50%, -50%);
        position: fixed;
        left: 50%;
        top: 50%;
        text-align: center;
        width: 100%;
        h5 {
            color: #000;
            padding: 30px 0;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 700;
        }
        button.error.btn.btn-primary {
            @include mbme-gradient();
            border: 1px solid transparent !important;
            font-size: 14px;
            padding: 6px 25px;
        }
        button.success.btn.btn-primary {
            @include mbme-gradient();
            border: 1px solid transparent !important;
            font-size: 14px;
            padding: 6px 25px;
        }
        p {
            font-size: 11px;
            color: #000;
            font-weight: 400;
        }
    }
}
.popup.hide {
    visibility: hidden;
    opacity: 0;
}
.popup.show {
    visibility: visible;
    opacity: 1;
}
.overlay.show {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: 9;
    opacity: 0.5;
}
.popup.show.error {
    border-top: 4px solid red;
}
.popup.show.success {
    border-top: 4px solid green;
}

.block.form-group { display: block; }
.hidden.form-group { display: none; }
.mbme-form {
    .form-control:focus { 
        box-shadow: 0 0 0 0.2rem #fff0e6;
        border: 1px solid transparent !important;
    }
    input {
        padding: 20px 10px;
        font-size: 12px;
        background: #F4F5F642 0% 0% no-repeat padding-box !important;
        border: 1px solid #70707030 !important;
        border-radius: 8px !important;
    }
    textarea {
        font-size: 12px;
        background: #F4F5F642 0% 0% no-repeat padding-box !important;
        min-height: 180px !important;
        border: 1px solid #70707030 !important;
        border-radius: 8px !important;
        resize: none !important;
    }
}
.hidd-md { display: none !important; }
.hidd-larg { display: none !important; }
.hidd-xs { display: block !important; } 

.close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #fff;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }


.values-block {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 6px 13px 48px #00000029;
    border-radius: 18px;
    opacity: 1;
    padding: 3rem;
}

.styled-ul {
    padding: 0 15px;
    list-style-type: none;
    span.dot {
        position: relative;
        display: block;
        line-height: 24px;
        &:after {
            content: "";
            position: absolute;
            height: 4px;
            width: 4px;
            background: #FE6703;
            border-radius: 50%;
            left: -12px;
            top: 10px;
        }
    }
}

a .change-link { color: $content !important; }

.position-block {
    margin: -7.5rem 0 0 0;
}
.page-title {
    transform: translate(0%, -50%);
    position: absolute;
    left: 9%;
    top: 50%;
    color: #fff;
}

.admin-login,
.enquiry {
    .footer-main { margin: 0px; }
}

.pagination {
    li.active { 
        background-color: #b1acac !important; 
        background-image: none !important; 
        a { color: #fff !important; }
    }
    li {
        font-size: 14px;
        background: #474647;
        margin: 0 3px;
        border-radius: 3px;
        padding: 3px 0px;
        a { 
            color: #fff !important; 
            padding: 3px 10px; 
        }
    }
} 

.slick-slide, .slick-slide *{ outline: none !important; }

.page-banner-wrapper {
    .banner-bg {
        min-height: 295px;
        background: $white;
    }
    .banner-content-align {
        position: absolute;
        left: 0px;
        top: 50%;
    }
}

.lf_dark_triangle  {
    position: absolute;
    height: auto;
    width: 27%;
    left: -13rem;
    top: 40%;
    animation: oscil 5s infinite;
}
.lf_light_triangle  {
    position: absolute;
    height: auto;
    width: 27%;
    left: -13rem;
    top: 40%;
    animation: oscil 3s infinite;
}
.rg_dark_triange {
    position: absolute;
    height: auto;
    width: 27%;
    right: -15rem;
    top: 30%;
    animation: oscil 5s infinite;
}
.rg_light_triangle  {
    position: absolute;
    height: auto;
    width: 27%;
    right: -16rem;
    top: 30%;
    animation: oscil 3s infinite;
}
.position-block .line-anim-right {
    right: 0rem;
    top: 6rem;
}


.arrow {
    border: solid #fff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }


/* Let's get this party started */
::-webkit-scrollbar {
    width: 8px;
}
 
/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 5px;
    border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #949494; 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
	background: #949494; 
}


.button-big {
    width: 120px;
    height: 40px;
}

button.mbme-btn {
    @include mbme-gradient();
    padding: 7px 20px;
    font-size: 11px;
    border: 1px solid transparent !important;
    outline: none;
}

button.mbme-menu-btn {
    @include mbme-gradient();
    padding: 7px 20px;
    font-size: 11px;
    border: 1px solid transparent !important;
    outline: none;
}



@media only screen and (min-width: 1550px)  {
    .col-md-10.col-sm-12.col-12.offset-md-1 {
        padding: 0px;
    }
    .container{
        max-width: 1600px !important;
    }
    h4 p {
        line-height: 50px;
        font-size: 54px;
    }
    .head-medium-two { font-size: 15px; }
    p.content-hash { font-size: 14px; }
    .gra-uline:after { height: 18px; }
    button.mbme-menu-btn {
        padding: 13px 35px;
        font-size: 16px;
        border-radius: 10px;
    }
    p.content, .content {
        font-size: 16px !important;
        line-height: 30px !important;
    }
    .button-big {
        width: 142px;
        height: 52px;
        opacity: 0.9;
    }
    .space-letter {
        font-size: 19px;
        letter-spacing: 15px;
        word-spacing: 10px;
        line-height: 18px !important;
    }
    .head-medium-block {
        font-size: 22px;
        font-weight: 700;
    }
    .head-medium-normal {
        font-size: 18px;
        font-weight: 700;
    }
    .mbme-form {
        input {
            padding: 33px 25px !important;
            font-size: 16px !important;
        }
        textarea {
            min-height: 253px !important;
            font-size: 16px;
        }
        select { 
            padding: 12px 25px !important;
            height: 70px;
        }
    } 
    .self-service {
        .content {
            line-height: 35px !important;
        }
        .loadmore span {
            font-size: 16px !important;
            padding: 20px 0 !important;
        }
    }
    .partner-slider {
        .each-partner  {
            .items {
                height: 470px !important;
            }
            p.content {
                font-size: 16px !important;
                line-height: 30px !important;
            }
        }
    } 
    .software-block {
        min-height: 460px !important;
    }
    .managed-service .ech-item {
        min-height: 260px !important;
        .p-bold {
            font-size: 16px; margin-top: 25px;
        }
    }
    /* Solutions Page */
    .page-title {
        left: 8% !important;
        h4 {
            line-height: 50px;
            font-size: 44px
        }
        p {
            font-size: 20px;
        }
    } 
}
@media only screen and (min-width: 576px) and (max-width: 768px)  {
    .mob-banner { left: calc(100% - 19rem) !important; }
} 

@media only screen and (min-width: 320px) and (max-width: 768px)  {
    .hidd-xs { display: none !important; } 
    .hidd-larg { display: block !important; }
    .banner-video { display: none; }
    .mob-banner { position: absolute; z-index: 0; right: 0; }
    .otherpage .head-wrap-er { 
        position: relative; 
        .hamburgen {
            height: 40px;
            width: 40px;
            padding: 9px 5px;
            background-image: linear-gradient(to right, #ec3b8d, #fc3773, #ff4057, #ff5137, #fe6703);
            border-radius: 3px;
        }
    }
    .head-wrap-er {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        padding: 15px 15px !important;
        .logo {
            img {
                height: 40px;
                width: 100px !important;
            }
        }
        .hamburgen img {
            z-index: 9;
            position: relative;
        }
        .menuclose {
            .menuitems{
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                z-index: 9;
                background-image: linear-gradient(to right, #ee0a77, #fb1c61, #ff3549, #ff4f2f, #fe6703);
                opacity: 0.9;
                transform: translate(100%, 0);
                transition: all 0.2s;
                backdrop-filter: opacity(45%);
            }
            img.hamb { display: block; }
        } 
        .menuopen {
            .menuitems{
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                z-index: 9;
                background-image: linear-gradient(to right, #ee0a77, #fb1c61, #ff3549, #ff4f2f, #fe6703);
                opacity: 0.9;
                transform: translate(0%, 0);
                transition: all 0.2s;
                backdrop-filter: opacity(45%);
            }
            img.hamb { display: none; }
            ul.socialmedia {
                position: absolute;
                left: 0;
                right: 0;
                display: block;
                margin: 0;
                padding: 0;
                text-align: center;
                bottom: 3%;
            }
        } 
        .closemenu {
            display: flex;
            justify-content: flex-end;
        }
        .menu.nav {
            position: absolute;
            left: 55%;
            top: 45%;
            transform: translate(-50%, -50%);
            a {
                display: flex;
                justify-content: flex-start;
                flex-flow: wrap;
                width: 100%;
                font-size: 18px;
                color: #fff !important;
                font-weight: 200;
                padding: 15px 0;
            }
            button.mbme-menu-btn.btn.btn-primary {
                padding: 16px 60px;
                opacity: 1;
                background: #fff;
                color: #FF5722;
                font-size: 16px;
                margin: 15px 15px 15px 0px;
            }
        } 
    }
    #banner { 
        padding: 0 !important; 
        .lf-blk {
            padding-top: 17rem;
        }
    }
        .navbar-collapse.collapse.show {
            position: fixed;
            left: 0;
            bottom: 0;
            right: 0;
            top: 0;
            background-image: linear-gradient(to right, #ec3b8d, #fc3773, #ff4057, #ff5137, #fe6703);
            opacity: 0.9;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            .menu.navbar-nav {
                display: flex;
                flex-wrap: wrap;
                margin: 10rem 0;
            }
            button.mbme-menu-btn.btn.btn-primary {
                padding: 8px 50px;
                background: #fff;
                color: #FF9800;
                font-size: 13px;
                margin: 12px 0;
            }
            .menu a {
                font-size: 14px;
                color: #ffffff !important;
                width: 100%;
                display: block;
                padding: 14px 7rem;
                font-weight: 300;
                text-transform: uppercase;
            }
        }


    .xs-align-center { 
        text-align: center !important;
    }
    .xs-align-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .position-block, .field-padd-3  {
        margin: 0px !important;
    }
    .field-padd {
        padding: 4rem 0;
    }
    .gra-uline:after {
        height: 7px;
    }
    h4 p {
        line-height: 30px;
        font-size: 26px;
    }
    .no-padd {
        padding: 0 15px !important;
    }
    .menu .navbar-nav {
        margin-bottom: 20px !important;
    }
    #cofort .lf-blk .icon img {
        height: auto !important;
        width: 50px !important;
    }
    #cofort .lf-blk img.download {
        width: 58% !important;
        height: auto !important;
    }
    .footer-main {
        margin-top: 7rem; 
        .menu-section {
            margin-bottom: 0px;
            .head-medium-two {
                text-align: left !important;
                width: 50%;
            }
            ul.socialmedia {
                padding: 0px;
            }
            .icon-block {
                @include justify-content(flex-start);
            }
        }
    }
    .header-block .menu a { color: #000 !important; }
    ::-webkit-scrollbar {
        height: 2px;
    }
    .page-banner-wrapper .banner-bg { min-height: 60px !important; }
    .page-title {
        h3 {
            font-size: 16px !important;
        }
        p {
            font-size: 14px !important; margin-bottom: 0px !important;
        }
    } 
    form.form-block { padding: 2rem 1rem !important; }
    .news-letter h4 { font-size: 20px !important;}
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    hidd-xs { display: none !important; } 
    .hidd-larg { display: block !important; }
    .banner-video { display: none; }
    .mob-banner { position: absolute; z-index: 0; right: 0; }
    .otherpage .head-wrap-er { 
        position: relative; 
        .hamburgen {
            height: 40px;
            width: 40px;
            padding: 9px 5px;
            background-image: linear-gradient(to right, #ec3b8d, #fc3773, #ff4057, #ff5137, #fe6703);
            border-radius: 3px;
        }
    }
    .head-wrap-er {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        padding: 15px 15px !important;
        .logo {
            img {
                height: 40px;
                width: 100px !important;
            }
        }
        .hamburgen img {
            z-index: 9;
            position: relative;
        }
        .menuclose {
            .menuitems{
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                z-index: 9;
                background-image: linear-gradient(to right, #ee0a77, #fb1c61, #ff3549, #ff4f2f, #fe6703);
                opacity: 0.9;
                transform: translate(100%, 0);
                transition: all 0.2s;
                backdrop-filter: opacity(45%);
            }
            img.hamb { display: block; }
        } 
        .menuopen {
            .menuitems{
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                z-index: 9;
                background-image: linear-gradient(to right, #ee0a77, #fb1c61, #ff3549, #ff4f2f, #fe6703);
                opacity: 0.9;
                transform: translate(0%, 0);
                transition: all 0.2s;
                backdrop-filter: opacity(45%);
            }
            img.hamb { display: none; }
            ul.socialmedia {
                position: absolute;
                left: 0;
                right: 0;
                display: block;
                margin: 0;
                padding: 0;
                text-align: center;
                bottom: 3%;
            }
        } 
        .closemenu {
            display: flex;
            justify-content: flex-end;
        }
        .menu.nav {
            position: absolute;
            left: 55%;
            top: 45%;
            transform: translate(-50%, -50%);
            a {
                display: flex;
                justify-content: flex-start;
                flex-flow: wrap;
                width: 100%;
                font-size: 18px;
                color: #fff !important;
                font-weight: 200;
                padding: 15px 0;
            }
            button.mbme-menu-btn.btn.btn-primary {
                padding: 16px 60px;
                opacity: 1;
                background: #fff;
                color: #FF5722;
                font-size: 16px;
                margin: 15px 15px 15px 0px;
            }
        } 
    }
}