@import "../../_theme/_anim.scss";

.milestone-wrapper {
  .pulse {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
    animation: pulseanim 2s infinite;
    position: absolute;
  }
  .pulse:hover {
    animation: none;
  }
  button:focus {
    outline: none;
  }
  .timeline-logo {
    width: 40% !important;
    margin-left: -5px;
  }

  .circleblock {
    text-align: center;
    padding: 2rem 0;
    img.border_circle {
      width: 75%;
      transform: rotate(270deg);
    }
    .inner_circle {
      cursor: pointer;
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
      animation: pulseanimbig 2s infinite;
      display: inline-block;
      background: transparent;
      height: 33%;
      width: 30%;
      border-radius: 50%;
      border: 2px solid #fff;
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: 50%;
      p {
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 30px;
        color: #fff;
      }
    }
    .cirlce_buttons {
      .year-click-btn {
        .yearButtons {
          button {
            border: none;
            background-color: transparent;
            color: #fff;
            font-size: 12px;
            position: relative;
            &:after {
              position: absolute;
              content: "";
              height: 3px;
              width: 3px;
              background-color: red;
              border-radius: 50%;
            }
          }
          &:nth-child(1) {
            position: absolute;
            top: 0px;
            left: 46%;
            button:after {
              left: 15px;
              top: 23px;
              background-image: linear-gradient(
                to right,
                #ec3b8d,
                #fc3773,
                #ff4057,
                #ff5137,
                #fe6703
              );
              height: 14px;
              width: 14px;
            }
            .pulse {
              display: none;
            }
          }
          &:nth-child(2) {
            position: absolute;
            top: 5%;
            right: 25%;
            button:after {
              left: -3px;
              top: 22px;
            }
            .pulse {
              top: 23px;
              left: -3px;
            }
          }
          &:nth-child(3) {
            position: absolute;
            top: 15%;
            right: 13%;
            button:after {
              left: -6px;
              top: 18px;
            }
            .pulse {
              top: 19px;
              left: -6px;
            }
          }
          &:nth-child(4) {
            position: absolute;
            top: 28%;
            right: 6%;
            button:after {
              left: -7px;
              top: 18px;
            }
            .pulse {
              top: 19px;
              left: -9px;
            }
          }
          &:nth-child(5) {
            position: absolute;
            top: 45%;
            right: 4%;
            button:after {
              left: -6px;
              top: 10px;
            }
            .pulse {
              top: 10px;
              left: -8px;
            }
          }
          &:nth-child(6) {
            position: absolute;
            top: 61%;
            right: 6%;
            button:after {
              left: -4px;
              top: 10px;
            }
            .pulse {
              top: 11px;
              left: -7px;
            }
          }
          &:nth-child(7) {
            position: absolute;
            top: 77%;
            right: 13%;
            button:after {
              left: -5px;
              top: 0px;
            }
            .pulse {
              top: 1px;
              left: -8px;
            }
          }
          &:nth-child(8) {
            position: absolute;
            top: 88%;
            right: 28%;
            button:after {
              left: 13px;
              top: -7px;
            }
            .pulse {
              top: -6px;
              left: 9px;
            }
          }
          &:nth-child(9) {
            position: absolute;
            top: 92%;
            right: 45%;
            button:after {
              left: 20px;
              top: -5px;
            }
            .pulse {
              top: -5px;
              left: 17px;
            }
          }
          &:nth-child(10) {
            position: absolute;
            top: 88%;
            right: 64%;
            button:after {
              left: 31px;
              top: -4px;
            }
            .pulse {
              top: -4px;
              left: 29px;
            }
          }
          &:nth-child(11) {
            position: absolute;
            top: 77%;
            right: 77%;
            button:after {
              left: 32px;
              top: 0px;
            }
            .pulse {
              top: 2px;
              left: 31px;
            }
          }
          &:nth-child(12) {
            position: absolute;
            top: 61%;
            right: 86%;
            button:after {
              left: 41px;
              top: 8px;
            }
            .pulse {
              top: 9px;
              left: 38px;
            }
          }
          &:nth-child(13) {
            position: absolute;
            top: 45%;
            right: 88%;
            button:after {
              left: 39px;
              top: 9px;
            }
            .pulse {
              top: 9px;
              left: 36px;
            }
          }
          &:nth-child(14) {
            position: absolute;
            top: 28%;
            right: 84%;
            button:after {
              left: 41px;
              top: 13px;
            }
            .pulse {
              top: 13px;
              left: 39px;
            }
          }
          &:nth-child(15) {
            position: absolute;
            top: 15%;
            right: 77%;
            button:after {
              left: 41px;
              top: 13px;
            }
            .pulse {
              top: 13px;
              left: 39px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1550px) {
  .milestone-card {
    h5.curryear {
      font-size: 35px !important;
      font-weight: 500;
    }

    .timeline-logo {
      width: 20% !important;
      margin-left: -5px;
    }
  }

  button.year-btn.active_item {
    font-weight: 600;
  }
  .cirlce_buttons {
    .year-click-btn {
      .yearButtons {
        button {
          &:after {
            height: 5px !important;
            width: 5px !important;
          }
        }
        &:nth-child(1) {
          position: absolute;
          top: 0px;
          left: 46%;
          button:after {
            left: 15px !important;
            top: 23px !important;
            background-image: linear-gradient(
              to right,
              #ec3b8d,
              #fc3773,
              #ff4057,
              #ff5137,
              #fe6703
            );
            height: 14px;
            width: 14px;
          }
          .pulse {
            display: none;
          }
        }
        &:nth-child(2) {
          right: 28% !important;
        }
        &:nth-child(3) {
          right: 16% !important;
        }
        &:nth-child(4) {
          right: 9% !important;
        }
        &:nth-child(5) {
          right: 6.5% !important;
        }
        &:nth-child(6) {
          top: 61%;
          right: 8.5% !important;
        }
        &:nth-child(7) {
          right: 15% !important;
        }
        &:nth-child(8) {
          top: 89% !important;
          right: 28%c !important;
        }
        &:nth-child(9) {
          top: 94% !important;
        }
        &:nth-child(10) {
          top: 90% !important;
        }
        &:nth-child(11) {
          right: 79.5% !important;
          top: 79% !important;
          button:after {
            left: 48px !important;
            top: 0px !important;
          }
          .pulse {
            top: 0px !important;
            left: 45px !important;
          }
        }
        &:nth-child(12) {
          right: 88.3% !important;
          button:after {
            left: 53px !important;
            top: 11px !important;
          }
          .pulse {
            left: 50px !important;
          }
        }
        &:nth-child(13) {
          right: 88% !important;
          button:after {
            right: 49px !important;
            top: 12px !important;
          }
          .pulse {
            left: 47px;
          }
        }
        &:nth-child(14) {
          right: 85.5% !important;
          top: 27% !important;
          button:after {
            left: 50px !important;
            top: 18px !important;
          }
          .pulse {
            left: 48px !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .cirlce_buttons {
    .year-click-btn {
      .yearButtons {
        button {
          &:after {
            height: 3px;
            width: 3px;
          }
        }
        &:nth-child(1) {
          position: absolute;
          top: 0px;
          left: 46%;
          button:after {
            left: 15px !important;
            top: 23px !important;
            background-image: linear-gradient(
              to right,
              #ec3b8d,
              #fc3773,
              #ff4057,
              #ff5137,
              #fe6703
            );
            height: 14px;
            width: 14px;
          }
          .pulse {
            display: none;
          }
        }
        &:nth-child(2) {
          right: 26% !important;
        }
        &:nth-child(3) {
          right: 14% !important;
        }
        &:nth-child(4) {
          right: 6.5% !important;
        }
        &:nth-child(5) {
          right: 4.5% !important;
        }
        &:nth-child(6) {
          top: 61%;
          right: 5.8% !important;
        }
        &:nth-child(7) {
          right: 12.4% !important;
        }
        &:nth-child(8) {
          top: 90% !important;
          right: 28%c !important;
        }
        &:nth-child(9) {
          top: 94% !important;
        }
        &:nth-child(10) {
          top: 89% !important;
        }
        &:nth-child(11) {
          right: 78.4% !important;
          top: 79% !important;
          button:after {
            left: 48px !important;
            top: 0px !important;
          }
          .pulse {
            top: 0px !important;
            left: 45px !important;
          }
        }
        &:nth-child(12) {
          right: 86.9% !important;
          button:after {
            left: 53px !important;
            top: 11px !important;
          }
          .pulse {
            left: 50px !important;
          }
        }
        &:nth-child(13) {
          button:after {
            left: 49px !important;
            top: 12px !important;
          }
          .pulse {
            left: 47px;
          }
        }
        &:nth-child(14) {
          button:after {
            left: 50px !important;
            top: 18px !important;
          }
          .pulse {
            left: 48px !important;
          }
        }
      }
    }
  }
}
