@import "../../_theme/variable.scss";
@import "../../_theme/flex.scss";

.page-banner-wrapper {
  /* .banner-bg {
        background-image: url('../../images/carrer-group.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    } */
}

form.form-block .mbme-form textarea {
  min-height: 174px !important;
}

.lff_dark_triangle  {
  position: absolute;
  height: auto;
  width: 27%;
  left: -13rem;
  top: 0%;
  animation: oscil 5s infinite;
}
.lff_light_triangle  {
  position: absolute;
  height: auto;
  width: 27%;
  left: -13rem;
  top: 0%;
  animation: oscil 3s infinite;
}
.rgg_dark_triange {
  position: absolute;
  height: auto;
  width: 27%;
  right: -15rem;
  top: 20%;
  animation: oscil 5s infinite;
}
.rgg_light_triangle  {
  position: absolute;
  height: auto;
  width: 27%;
  right: -16rem;
  top: 20%;
  animation: oscil 3s infinite;
}

.help-support-head {
  margin-top: 100px;
  text-align: center;
}

h5.head-no-bold {
  font-weight: normal;
}
h4.head-bold {
  font-weight: 600;
}
.vision-block {
  padding: 0 3rem;
}

.vision-content {
  margin-left: 25px;
  margin-top: -45px;
}

.quotes {
  font-size: 60px;
  color: #ee0a77;
}

.head-medium-normal {
  margin-bottom: 20px;
}

.timeline {
  background-image: url("../../images/milestone-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 1.5rem 0 1.5rem 0;

  .milestone-card {
    background: #fff;
    min-height: 270px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    padding: 30px;
    .img-row {
      @include flexbox();
      @include justify-content(start);
      @include align-items(center);
      margin: 10px 0;
      img {
        width: 75px;
        height: auto;
        margin: 0 15px 0 0;
      }
      h5.curryear {
        font-size: 33px;
        font-weight: 700;
        color: #000;
      }
    }
    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 25px;
      background-image: linear-gradient(
        to top,
        #ec3b8d,
        #fc3773,
        #ff4057,
        #ff5137,
        #fe6703
      );
      z-index: 9;
      display: block;
      margin: auto 0;
      left: -5px;
      top: 0px;
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
    }
  }
}

.milestone-wrapper {
  .main-head {
    h2 {
      opacity: 0.19;
      color: #fff;
      text-transform: uppercase;
      font-size: 4em;
      font-weight: 600;
    }
    span {
      position: absolute;
      top: 27px;
      left: 30px;
      font-size: 16px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px)  { 
  .data-card .ech-item { min-height: 150px; }
}