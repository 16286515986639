@import "../../_theme/variable.scss";
@import "../../_theme/flex.scss";

.solution-wrapper {
  .tabData {
    padding-top: 3em;
  }
  .head-medium-normal {
    min-height: 33px;
  }
  .sol-part-tab-header {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    border-radius: 5px;
    background: none;
    border: 1px solid $border !important;
    color: #1a1e23;
  }
  .nav-item {
    text-align: center;
    a {
      font-size: 12px;
      color: $content;
    }
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-image: $gradient-color;
    color: $white;
    padding: 12px 15px;
    box-shadow: 6px 7px 32px #fe670347;
    opacity: 1;
  }
}

.padd-top-3 {
  padding-top: 3rem;
}

.card-block {
  //margin: 0 -15px;
  .each-card-block {
    /* border: 1px solid $border;
    padding: 1.5rem;
    border-radius: 20px;
    min-height: 575px;
    margin: 30px 15px; */
    position: relative;
    border: 2px solid transparent;
    border-radius: 16px;
    background: #fff;
    background-clip: padding-box;
    padding: 1.5rem;
    min-height: 575px;
    margin: 30px 15px;
    &:after {
      position: absolute;
      top: -2px; 
      bottom: -2px;
      left: -2px; right: -2px;
      background-image: linear-gradient(to right top, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2);
      content: '';
      z-index: -1;
      border-radius: 16px;
    }
    &:hover {
      &:after {
        background-image: linear-gradient(to right, #ed0071, #f22d82, #f74392, #fb56a2, #ff67b1);
        box-shadow: 0px 18px 19px #0000000a;
      }
    }
    h5 {
      font-weight: 400;
      font-size: 14px;
    }
    /* &:hover {
      border: 1px solid $magenta;
    } */
    .kiosk-features {
      height: 210px !important;
    }
  }
}

.blk-content {
  height: 115px;
  margin: 0;
}

.hlp-card {
  .data-card .ech-item {
    min-height: 265px;
  }
}

.tabData.self-service .slick-slider {
  margin: 0 -15px;
}

.mbme-slidr {
  .slick-slider {
    margin: 0 -15px !important;
  }
}

span.slickbtn {
  width: 30px;
  height: 30px;
  margin: 0 0px 0 5px;
  background-image: linear-gradient(
    to right,
    #ec3b8d,
    #fc3773,
    #ff4057,
    #ff5137,
    #fe6703
  );
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  i.arrow.left {
    position: absolute;
    left: 11px;
    top: 10px;
  }
  i.arrow.right {
    position: absolute;
    left: 9px;
    top: 10px;
  }
}

.managed-service {
  .ech-item {
      position: relative;
      border: 2px solid transparent;
      border-radius: 16px;
      background: #fff;
      background-clip: padding-box;
      padding: 1.5rem;
      min-height: 200px;
      margin: 30px 0 0 0;
      .align-data {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
      img {
        background: linear-gradient(
          to right,
          #ec3b8d,
          #fc3773,
          #ff4057,
          #ff5137,
          #fe6703
        );
        padding: 14px;
        height: 60px;
        width: 60px;
        border-radius: 50%;
      }
      &:after {
        position: absolute;
        top: -2px; 
        bottom: -2px;
        left: -2px; right: -2px;
        background-image: linear-gradient(to right top, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2);
        content: '';
        z-index: -1;
        border-radius: 16px;
      }
      &:hover {
        &:after {
          background-image: linear-gradient(to right, #ed0071, #f22d82, #f74392, #fb56a2, #ff67b1);
          box-shadow: 4px 15px 45px #00000017;
        }
      }
  }
  /* .ech-item {
    border: 1px solid $border;
    margin: 30px 0 0 0;
    min-height: 200px;
    border-radius: 18px;
    transition: all 0.2s ease-in;
    .align-data {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    img {
      background: linear-gradient(
        to right,
        #ec3b8d,
        #fc3773,
        #ff4057,
        #ff5137,
        #fe6703
      );
      padding: 14px;
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
    &:hover {
      cursor: pointer;
      border: 1px solid $magenta;
    }
  } */
}

.software-block {
  min-height: 425px !important;
}

.part-img-4 {
  width: 137px !important;
}

.each-card-block.software-block {
  min-height: 470px !important;
}

#cofort {
  background-image: url("../../images/Path 12.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 60px;
  .lf-blk {
    .icon {
      @include flexbox();
      @include justify-content(flex-start);
      @include align-items(center);
      img {
        width: 55px;
        margin: 0 12px 0 0px;
      }
    }
    h4.bold-hash-head {
      font-size: 36px;
      opacity: 0.17;
      margin: 15px 0;
    }
    h4 p {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
    }
    img.download {
      width: 58%;
      margin: 10px -10px;
    }
  }
  .rg-blk {
    .mobile-pic {
      animation-name: bounce-1;
      animation-timing-function: linear;
      animation-duration: 5s;
      animation-iteration-count: infinite;
    }
  }
}

.gov-block {
  padding: 3em;
  position: relative;
  border-radius: 23px;
  border: 2px solid #fb56a2;
  /* border: 1px solid transparent;
  border-radius: 30px;
  background: #fff;
  background-clip: padding-box;
  &:after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    content: '';
    z-index: -1;
    border-radius: 30px;
    background-image: linear-gradient(to right, #ed0071, #f22d82, #f74392, #fb56a2, #ff67b1);
  } */
  .ech-item {
      position: relative;
      border: 2px solid transparent;
      border-radius: 16px;
      background: #fff;
      background-clip: padding-box;
      min-height: 112px;
      padding: 65px 0;
      margin: 8px 0;
      .align-data {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
      img {
        padding: 10px;
        height: 80px;
        width: 80px;
        border-radius: 50%;
      }
      &:after {
        position: absolute;
        top: -2px; 
        bottom: -2px;
        left: -2px; right: -2px;
        background-image: linear-gradient(to right top, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2);
        content: '';
        z-index: -1;
        border-radius: 16px;
      }
      &:hover {
        &:after {
          background-image: linear-gradient(to right, #ed0071, #f22d82, #f74392, #fb56a2, #ff67b1);
          box-shadow: 0px 18px 19px #0000000a;
        }
      }
  }
  /* .ech-item {
    border-radius: 18px;
    transition: all 0.2s ease-in;
    min-height: 112px;
    transition: all 0.3s;
    .align-data {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    img {
      padding: 10px;
      height: 80px;
      width: 80px;
      border-radius: 50%;
    }
    &:hover {
      cursor: pointer;
      border: 1px solid $magenta;
    }
  } */
  .lf-blk {
    .icon {
      @include flexbox();
      @include justify-content(flex-start);
      @include align-items(center);
      img {
        width: 55px;
        margin: 0 12px 0 0px;
      }
    }
    h4.bold-hash-head {
      font-size: 36px;
      opacity: 0.17;
      margin: 15px 0;
    }
    h4 p {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
    }
    img.download {
      width: 58%;
      margin: 10px -10px;
    }
  }
}

.self-service {
  img.ico-img {
    background: linear-gradient(
      to right,
      #ec3b8d,
      #fc3773,
      #ff4057,
      #ff5137,
      #fe6703
    );
    padding: 10px;
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .content {
    line-height: 26px !important;
  }
  .styled-ul span.dot:after {
    height: 5px !important;
    width: 5px !important;
    left: -15px !important;
  }
  .loadmore {
    span {
      cursor: pointer;
      text-align: center;
      display: block;
      background-image: linear-gradient(
        to right,
        #ec3b8d,
        #fc3773,
        #ff4057,
        #ff5137,
        #fe6703
      );
      font-size: 12px;
      color: #fff;
      font-weight: 400;
      padding: 10px 0;
      border-radius: 8px;
    }
  }
}

.partner-slider {
  margin: 0 -15px;
  .slick-dots li {
    margin: 0px !important;
    &.slick-active button:before {
      color: $orange !important;
    }
  }
  .each-partner {
    .items {
      position: relative;
      border: 2px solid transparent;
      border-radius: 16px;
      background: #fff;
      background-clip: padding-box;
      padding: 1.5rem;
      height: 460px;
      margin: 30px 15px;
      &:after {
        position: absolute;
        top: -2px; 
        bottom: -2px;
        left: -2px; right: -2px;
        background-image: linear-gradient(to right top, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2);
        content: '';
        z-index: -1;
        border-radius: 16px;
      }
      &:hover {
        &:after {
          background-image: linear-gradient(to right, #ed0071, #f22d82, #f74392, #fb56a2, #ff67b1);
          box-shadow: 0px 18px 19px #0000000a;
        }
      }
    }
    /* .items {
      border: 1px solid $border;
      padding: 35px;
      border-radius: 25px;
      height: 460px;
      margin: 0 20px 30px 20px;
      transition: all 0.2s ease-in;
      &:hover {
        border: 1px solid $magenta;
      }
    } */
    .logo-part {
      height: 75px;
    }
    img {
      height: auto;
      width: 90px;
      display: block;
      margin: auto;
    }
    img.part-img-3 {
      width: 175px;
    }
    h4 {
      min-height: 20px !important;
    }
    p.content {
      font-size: 11px;
      font-weight: 400 !important;
      line-height: 18px;
    }
    p.content-oneline {
      height: 35px;
      font-size: 11px;
      font-weight: 400;
    }
    p.operator {
      color: $magenta;
      font-size: 4rem;
      height: 35px;
    }
  }
}

.kiosk-features {
  height: 220px;
}

.kiosk-applications {
  height: 112px;
}

.kiosk {
  margin-bottom: 5rem;
}
.kiosk-two {
  padding: 3rem 0;
  background-image: url("../../images/rose-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .mach-block {
    background-image: url("../../images/Ellipse 47.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
  }
}

.wrap-img {
  height: 110px;
}

.kiosk {
  .ultra {
    img {
      width: 110px;
    }
    h4 {
      height: 20px;
      margin: 10px 0;
    }
    span {
      min-height: 45px;
      display: block;
    }
  }

  .mach-block {
    background-image: url("../../images/Ellipse-small.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    img {
      width: 25%;
    }
  }
  .field-mention {
    .item-one {
      position: absolute;
      top: 7rem;
      left: 6rem;
      img {
        position: absolute;
        width: 70%;
        left: 13rem;
        top: 0;
      }
    }
    .item-two {
      position: absolute;
      top: 15rem;
      left: 4rem;
      img {
        position: absolute;
        width: 80%;
        left: 13rem;
        top: -1rem;
      }
    }
    .item-three {
      position: absolute;
      top: 21rem;
      left: 4rem;
      img {
        position: absolute;
        width: 80%;
        left: 13rem;
        top: -1rem;
      }
    }
    .item-four {
      position: absolute;
      top: 27rem;
      left: 5rem;
      img {
        position: absolute;
        width: 80%;
        left: 13rem;
        top: -2rem;
      }
    }
    .item-five {
      position: absolute;
      top: 8rem;
      right: 3rem;
      img {
        position: absolute;
        width: 75%;
        right: 14rem;
        top: -1rem;
      }
    }
    .item-six {
      position: absolute;
      top: 14rem;
      right: 2rem;
      img {
        position: absolute;
        width: 80%;
        right: 13rem;
        top: 0rem;
      }
    }
    .item-seven {
      position: absolute;
      top: 20rem;
      right: 2rem;
      img {
        position: absolute;
        width: 80%;
        right: 13rem;
        top: 0rem;
      }
    }
    .item-eight {
      position: absolute;
      top: 26rem;
      right: 3rem;
      img {
        position: absolute;
        width: 80%;
        right: 13rem;
        top: -1rem;
      }
    }

    .card-item {
      border: 1px solid whitesmoke;
      padding: 10px 10px;
      border-radius: 8px;
      width: 210px;
      h5 {
        font-size: 13px !important;
        font-weight: 600 !important;
      }
      p.content {
        font-size: 12px !important;
        font-family: "poppins", regular !important;
        line-height: 21px !important;
      }
    }
  }
}

.kiosk-two {
  .ultra {
    img {
      width: 110px;
    }
    h4 {
      height: 20px;
      margin: 10px 0;
    }
    span {
      min-height: 45px;
      display: block;
    }
  }

  .mach-block {
    background-image: url("../../images/Ellipse-small.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    img {
      width: 22%;
    }
  }
  .field-mention {
    .item-one {
      position: absolute;
      top: 6rem;
      left: 6rem;
      img {
        position: absolute;
        width: 85%;
        left: 13rem;
        top: 1em;
      }
    }
    .item-two {
      position: absolute;
      top: 14rem;
      left: 5rem;
      img {
        position: absolute;
        width: 85%;
        left: 13rem;
        top: -1rem;
      }
    }
    .item-three {
      position: absolute;
      top: 21rem;
      left: 6rem;
      img {
        position: absolute;
        width: 85%;
        left: 13rem;
        top: -1rem;
      }
    }
    .item-four {
      position: absolute;
      top: 27rem;
      left: 6rem;
      img {
        position: absolute;
        width: 85%;
        left: 13rem;
        top: -2rem;
      }
    }
    .item-five {
      position: absolute;
      top: 7rem;
      right: 3rem;
      img {
        position: absolute;
        width: 85%;
        right: 13rem;
        top: 0rem;
      }
    }
    .item-six {
      position: absolute;
      top: 14rem;
      right: 2rem;
      img {
        position: absolute;
        width: 75%;
        right: 13rem;
        top: -1rem;
      }
    }
    .item-seven {
      position: absolute;
      top: 20rem;
      right: 2rem;
      img {
        position: absolute;
        width: 85%;
        right: 13rem;
        top: 0rem;
      }
    }
    .item-eight {
      position: absolute;
      top: 27rem;
      right: 3rem;
      img {
        position: absolute;
        width: 85%;
        right: 13rem;
        top: -2rem;
      }
    }

    .card-item {
      border: 1px solid transparent;
      padding: 10px 10px;
      border-radius: 8px;
      width: 210px;
      cursor: pointer;
      h5 {
        font-size: 13px !important;
        font-weight: 600 !important;
      }
      p.content {
        font-size: 12px !important;
        font-family: "poppins", regular !important;
        line-height: 21px !important;
      }
      &:hover {
        border: 1px solid #fff;
        background-color: #fff;
        box-shadow: 0px 33px 39px #0000001a;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .tabData {
    .each-block {
      .card-block {
        text-align: center;
        margin: 0 0 30px 0;
        .styled-ul span.dot:after {
          display: none !important;
        }
      }
    }
  }
  .pageview {
    .solution-wrapper {
      .sol-part-tab-header {
        .nav-item {
          width: 100%;
        }
      }
    }
  }

  .managed-service .ech-item { min-height: 140px !important; }

  .card-block .each-card-block .kiosk-features {
    height: 145px !important;
  }
  .partner-slider .each-partner .items { height: 405px !important; }
  .self-service img.ico-img {
    display: block; margin: auto;
  }
  #solution-tabpane-four {
    .software-block {
      min-height: 375px !important;
    }
  }
  .kiosk .field-mention, 
  .kiosk-two .field-mention,
  .kiosk-three  .field-mention {
    display: none;
  }
  .kiosk .mach-block { background-size: 73%; img { width: 33%; } } 
  .kiosk-two .mach-block img { background-size: 78%; img { width: 33%; } }
  .kiosk-three .mach-block { background-size: 68%; img { width: 50%; } } 
}




/* Hd Media Query */
@media only screen and (min-width: 1550px)  { 
  .solution-wrapper {
    .nav-link.active, .nav-pills .show > .nav-link { padding: 18px 15px !important; }
    .nav-item {
      a {
        font-size: 16px;
      }
    }
  } 
  .solution-wrapper .head-medium-normal { min-height: 45px !important; }
  .gov-block {
    .lf-blk {
      .p-bold {
        padding: 20px 0;
      }
    }
    .p-bold {
      font-size: 18px;
      font-weight: 500;
    }
    .ech-item {
      min-height: 160px;
      img {
        height: 85px;
        width: 85px;
      }
    }
  }

  .kiosk{
    .field-mention {
      .item-one img{
          width: 90% !important;
          left: 18rem !important;
      }
      .item-two { 
        top: 19rem; 
        img {
          width: 90% !important;
          left: 18rem !important;
        }
      } 
      .item-three {
        top: 29rem;
        img {
          width: 90% !important;
          left: 18rem !important;
        }
      } 
      .item-four {
        top: 39rem;
        img {
          width: 90% !important;
          left: 18rem !important;
        }
      } 
      .item-five {
        img {
          width: 90% !important;
          right: 18rem !important;
        }
      } 
      .item-six {
        top: 18rem !important;
        img {
          width: 90% !important;
          right: 18rem !important;
        }
      } 
      .item-seven {
        top: 28rem !important;
        img {
          width: 90% !important;
          right: 18rem !important;
        }
      } 
      .item-eight {
        top: 38rem !important;
        img {
          width: 90% !important;
          right: 18rem !important;
        }
      } 
      .card-item {
        width: 290px !important;
        padding: 15px 15px !important;
        min-height: 100px;
        h5 {
          font-size: 20px !important;
        }
        p.content {
          font-size: 16px !important;
        }
      } 
    } 
  } 

  .kiosk-two{
    .field-mention { 
      .item-one {
        img {
          width: 93%;
          left: 18rem;
          top: 0em;
        }
      } 
      .item-two {
        top: 16rem;
        img {
          width: 93%;
          left: 18rem;
          top: 0em;
        }
      } 
      .item-three {
        top: 26rem;
        img {
          width: 93%;
          left: 18rem;
          top: 0em;
        }
      } 
      .item-four {
        top: 36rem;
        img {
          width: 93%;
          left: 18rem;
          top: 0em;
        }
      } 
      .item-five {
        img {
          width: 85%;
          right: 18rem;
        }
      } 
      .item-six {
        top: 17rem;
        img {
          width: 85%;
          right: 18rem;
        }
      } 
      .item-seven {
        top: 26rem;
        img {
          width: 85%;
          right: 18rem;
        }
      } 
      .item-eight {
        top: 38rem;
        img {
          width: 85%;
          right: 18rem;
        }
      }
      .card-item {
        width: 290px !important;
        padding: 15px 15px !important;
        min-height: 100px;
        h5 {
          font-size: 20px !important;
        }
        p.content {
          font-size: 16px !important;
        }
      } 
    }
  }




}

.min-hght-756 {
  margin-bottom :30%;
}
.mg-bottom-32-perc {
  margin-bottom :32%;
}