.admin-login {
    .enquiry {
        thead tr th {
            font-weight: 500;
            font-size: 13px;
        }
        tbody tr td {
            font-weight: 400;
            font-size: 13px;
        }
    } 
    
    .login-button {
        button {
            background-image: linear-gradient(to right, #ec3b8d, #fc3773, #ff4057, #ff5137, #fe6703);
            padding: 7px 30px;
            font-size: 12px;
            border: 1px solid transparent !important;
            outline: none;
            color: #fff;
            border-radius: 5px;
        }
    }
    .mbme-form {
        .form-group {
            margin-bottom: 0rem !important;
        }
    }
    .form-change-password {
        .form-group {
            margin-bottom: 0rem !important;
        }
    }
}

.error {
    color: red;
}

.mbme-cbutton {
    background-image: linear-gradient(to right, #ec3b8d, #fc3773, #ff4057, #ff5137, #fe6703);
    padding: 7px 20px;
    font-size: 11px;
    border: 1px solid transparent !important;
    outline: none;
    color: white;
    border-radius: 5px;
}

table {
    font-size: 12px;
}

.loadertrue {
    position: fixed;
    background: #040404cf;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999999;
    .loader-show {
        position: absolute;
        left: 50%;
        top: 50%;
        border: 5px solid #f3f3f3;
        border-radius: 50%;
        border-top: 5px solid #E91E63;
        width: 80px;
        height: 80px;
        animation: spin 2s linear infinite;
    }
}
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}