@import "../../_theme/variable.scss";
@import "../../_theme/flex.scss";

#banner {
  .banner-video img {
    position: absolute;
    width: calc(100% - 34%);
    height: auto;
    left: 33rem;
    top: -21rem;
  }
  .lf-blk {
    margin: 5rem 0 6rem 0;
  }
}

.mbme_clients {
  margin-right: 15px;
}
.client-image {
  transition: all 2s;
}

.client-image:hover {
  filter: grayscale(0%);
  transform: scale(1.3);
}

#about {
  &.field-padd {
    padding: 6rem 0;
  }
  .about-row {
    .tab-content {
      min-height: 214px;
    }
  }

  .about-tab-button {
    .tab-buttons {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      .ech-btn.nav-item {
        border-radius: 5px;
        width: 200px;
        height: 63px;
        a {
          color: $content;
          border: 1px solid $btn-border;
          font-size: 12px;
          height: 100%;
          border-radius: 10px;
          @include flexbox();
          @include justify-content(space-around);
          @include align-items(center);
          transition: all 0.3s;
          &:hover {
            box-shadow: 0px 19px 18px #f882362b !important;
            border: 1px solid transparent !important;
          }
        }
        img {
          width: 20px;
          height: auto;
          animation: left-to-right 2s infinite;
          position: relative;
        }
      }
    }
  }
  .nav-pills .nav-link.active,
  .nav-pills a.active .nav-pills .show > .nav-link {
    background: none;
    box-shadow: 0 4px 6px 2px #f882362b;
    border: transparent !important;
  }

  .about-banner h3 {
    text-align: center;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 50px;
    color: #ffffff;
    opacity: 0.2;
  }
}

.youtube-video {
  height: 500px;
}

.line-anim-right {
  position: absolute;
  height: 15%;
  width: 15%;
  z-index: -1;
  right: 4rem;
  top: -1rem;
  transform: rotate(135deg);
}
.line-anim-left {
  position: absolute;
  height: 15%;
  width: 15%;
  z-index: -1;
  left: 4rem;
  top: -1rem;
  transform: rotate(135deg);
}
.line-anim-right,
.line-anim-left {
  span {
    content: "";
    position: absolute;
    height: 1px;
    border: 0.5px dotted $btn-border;
    border-radius: 0px;
    &:nth-child(1n) {
      width: 125px;
      top: 0;
      animation: lineanim 4s infinite;
    }
    &:nth-child(2n) {
      width: 125px;
      top: 18px;
      animation: lineanim 4.5s infinite;
    }
    &:nth-child(3n) {
      width: 125px;
      top: 28px;
      animation: lineanim 4.8s infinite;
    }
  }
}

#weoffer {
  .offer-card-plane,
  .offer-card-yea {
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 15px;
    min-height: 205px;
    .pathline-plane {
      display: inline-block;
      background-size: contain;
      img {
        height: auto;
      }
    }

    p.content-hash {
      min-height: 90px;
    }
  }
  .offer-slider {
    /* .off-card {
            box-shadow: 0px 33px 39px #0000000F;
        } */
    .slick-list {
      padding: 60px 20px;
    }
    .slick-slide {
      padding: 0 20px;
      /* &:nth-child(odd) { 
                .off-card { box-shadow: 0px 33px 39px #0000000F; }
            }
            &:nth-child(even) { 
                .off-card { box-shadow: 0px 33px 39px #0000000F; }
            } */
    }
    .slick-slide.slick-active.slick-current .off-card {
      box-shadow: 0px 33px 39px #0000000f;
    }
    .slick-slide.slick-active ~ .slick-slide .off-card {
      box-shadow: none !important;
    }
    .slick-slide.slick-active + .slick-slide .off-card {
      box-shadow: none !important;
      background-color: #f4f5f6 !important;
    }
    ul.slick-dots {
      position: absolute;
      bottom: 0;
      left: 15px;
    }
    .slick-dots li {
      width: 5px;
    }
    .slick-dots li button:before {
      color: #ff5137;
    }
  }
}

#solution {
  padding: 0rem 0 2rem 0;
  .line-anim-right {
    top: 8rem;
  }
  .about-lf-image-f {
    left: -15rem;
    top: 8rem;
  }
  .about-lf-image-s {
    left: -17rem;
    top: 8rem;
  }

  .machine3 {
    img {
      width: 26%;
      height: auto;
      margin: auto;
      margin-top: 15%;
    }
  }

  .kioskImage {
    img {
      width: 26%;
      height: auto;
      margin: auto;
    }
  }
  .machine-blk {
    .mach-sec {
      background-image: url("../../images/Ellipse 5.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      h5 {
        font-size: 15px;
        font-weight: 600;
      }
      .card-itm {
        border: 1px solid whitesmoke;
        padding: 10px 10px;
        border-radius: 8px;
        &:hover {
          cursor: pointer;
          border: 1px solid transparent;
          box-shadow: 0px 33px 39px #0000000f;
        }
      }
    }
    img {
      width: 26%;
      height: auto;
    }
    .card-item {
      transition: all 0.2s;
      background-color: $white;
      border-radius: 8px;
      h5 {
        font-size: 15px;
        font-weight: 600;
      }
      &:hover {
        cursor: pointer;
        box-shadow: 0px 33px 39px #0000001a;
      }
    }
    .field-block {
      .item-one {
        position: absolute;
        right: 10rem;
        top: 8rem;
        img {
          width: 240px;
          right: 21rem;
        }
        .card-item {
          position: absolute;
          right: -11rem;
          border: 1px solid transparent;
          padding: 15px 30px;
        }
      }
      .item-two {
        position: absolute;
        right: 10rem;
        top: 16rem;
        img {
          width: 240px;
          right: 21rem;
        }
        .card-item {
          position: absolute;
          right: -12rem;
          border: 1px solid transparent;
          padding: 5px 20px;
          top: 50px;
        }
      }
      .item-three {
        position: absolute;
        right: 9rem;
        top: 21rem;
        img {
          width: 240px;
          right: 21rem;
        }
        .card-item {
          position: absolute;
          right: -8rem;
          border: 1px solid transparent;
          padding: 5px 20px;
          top: 46px;
        }
      }
      .item-four {
        position: absolute;
        right: 9rem;
        top: 25rem;
        img {
          width: 240px;
          right: 21rem;
        }
        .card-item {
          position: absolute;
          right: -7rem;
          border: 1px solid transparent;
          padding: 5px 20px;
        }
      }
    }
  }
  .sol-data {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0%, -50%);
    h4 p {
      font-size: 19px;
      line-height: 30px;
      font-weight: 500;
    }
  }
}

#cofort {
  background-image: url("../../images/Path 12.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .lf-blk {
    .icon {
      @include flexbox();
      @include justify-content(flex-start);
      @include align-items(center);
      img {
        width: 125px;
        margin: 0 0 0 -35px;
      }
    }
    h4.bold-hash-head {
      font-size: 36px;
      opacity: 0.17;
      margin: 15px 0;
    }
    h4 p {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
    }
    img.download {
      width: 58%;
      margin: 10px -10px;
    }
  }
  .rg-blk {
    .mobile-pic {
      animation-name: bounce-1;
      animation-timing-function: linear;
      animation-duration: 5s;
      animation-iteration-count: infinite;
    }
  }
}

#customer {
  .customer-view {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    background-image: url("../../images/Rectangle 20.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    padding: 4rem 3rem;
    h4,
    p {
      margin: 0px;
    }
    .ech-count {
      h4 {
        color: $white;
        font-weight: 700;
        font-size: 23px;
      }
      p {
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
}

#partner {
  span {
    @include flexbox();
    @include justify-content(flex-start);
    @include align-items(center);
    cursor: pointer;
  }
  img.dottedimg {
    position: absolute;
    height: 210px;
    bottom: -20px;
    right: 0px;
    z-index: -1;
  }
  .ech-part-btn {
    span {
      border: 1px solid transparent;
      padding: 23px 0;
      display: block;
      width: 290px;
      box-shadow: 0px 33px 39px #0000000f;
      border-radius: 8px;
      font-size: 13px;
      margin: 0 auto;
      text-align: center;
      position: relative;
      img {
        width: 20px;
        animation: oscil2 2s infinite;
        position: absolute;
      }
    }
    &:nth-child(1) {
      span {
        background-color: #ffffff;
        &:hover {
          background-color: #f4f5f6 !important;
        }
      }
    }
    &:nth-child(2) {
      span {
        background-color: #f4f5f6;
        &:hover {
          background-color: #ffffff !important;
        }
      }
    }
  }
  img.about-rg-image-s {
    position: absolute;
    height: auto;
    width: 27%;
    right: -8rem;
    top: 0rem;
    animation: oscil 6s infinite;
  }
  img.about-rg-image-f {
    position: absolute;
    height: auto;
    width: 27%;
    right: -7rem;
    top: 0rem;
    animation: oscil 6s infinite;
  }
}

.about-lf-image-f {
  position: absolute;
  height: auto;
  width: 27%;
  left: -17rem;
  top: -7rem;
  animation: oscil 6s infinite;
}
.about-lf-image-s {
  position: absolute;
  height: auto;
  width: 27%;
  left: -16rem;
  top: -7rem;
  animation: oscil 5s infinite;
}

.about-rg-image-f {
  position: absolute;
  height: auto;
  width: 25%;
  right: -18rem;
  top: -12rem;
  animation: oscil 6s infinite;
}
.about-rg-image-s {
  position: absolute;
  height: auto;
  width: 21%;
  right: -13rem;
  top: -10rem;
  animation: oscil 6s infinite;
}

#client {
  .slickbtn {
    background: transparent;
    position: absolute;
    z-index: 1;
    bottom: -50px;
    &.prev {
      left: 46%;
      &:hover {
        animation-name: move-left-to-right;
        animation-duration: 1s;
        animation-delay: 0.6s;
        animation-iteration-count: infinite;
        animation-direction: alternative;
      }
    }
    &.next {
      right: 46%;
      &:hover {
        animation-name: move-right-to-left;
        animation-duration: 1s;
        animation-delay: 0.6s;
        animation-iteration-count: infinite;
        animation-direction: alternative;
      }
    }
    img {
      height: 20px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  #banner .lf-blk {
    margin: 2rem 0 3rem 0;
  }
  #about {
    .tab-buttons {
      display: block !important;
      .ech-btn {
        @include flexbox();
        width: 100% !important;
        margin: 0 0 20px 0;
        &.nav-item a {
          @include justify-content(center);
        }
      }
    }
    .nav-pills .nav-link.active,
    .nav-pills a.active .nav-pills .show > .nav-link,
    .nav-pills .nav-link {
      width: 100% !important;
    }
    .about-tab-button {
      margin-bottom: 0px !important;
    }
    .about-banner {
      margin-top: 0px;
      h3 {
        font-size: 30px;
      }
    }
  }
  #weoffer {
    .no-padd.container .row,
    .no-padd.container .space-letter {
      text-align: center;
      display: block;
    }
    .offer-card-plane {
      margin: 30px 0;
    }
  }

  #solution {
    .machine-blk {
      .sol-data {
        h4,
        p {
          display: none;
        }
      }
    }
    .mach-sec {
      .blk-one,
      .blk-two,
      .blk-three,
      .blk-four,
      .blk-five,
      .blk-six {
        display: none;
      }
    }
  }

  #cofort {
    .lf-blk {
      text-align: center;
      .icon {
        @include justify-content(center !important);
      }
    }
    .rg-blk {
      img {
        height: 300px;
      }
    }
  }

  #customer {
    .customer-view {
      margin: 0 0 !important;
      padding: 10px 10px !important;
      flex-wrap: wrap;
      justify-content: center;
      .ech-count {
        margin: 10px 15px;
      }
    }
  }

  #partner {
    text-align: center;
    .ech-ret-btn span {
      padding: 20px 0;
    }
    h5.space-letter {
      display: block;
      text-align: center;
      width: 100%;
    }
    .butt-blk {
      margin: 0px !important;
    }
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1549px) {
  .banner-video img {
    width: calc(100% - 32%) !important;
    left: 36rem !important;
    top: -25rem !important;
  }
}
@media only screen and (min-width: 1550px) and (max-width: 1700px) {
  .banner-video img {
    width: calc(100% - 40%) !important;
    left: 48rem !important;
    top: -25rem !important;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 2000px) {
  .banner-video img {
    width: calc(100% - 34%) !important;
    left: 48rem !important;
    top: -28rem !important;
  }
  #banner .lf-blk {
    margin: 10rem 0 10rem 0;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 1899px) {
  section#client {
    margin: 0px;
  }
}

@media only screen and (min-width: 1900px) and (max-width: 2000px) {
  section#client {
    margin: 5rem 0 2rem 0;
  }
}

@media only screen and (min-width: 1550px) {
  section#banner {
    p.content {
      margin: 25px 0 !important;
    }
  }
  section#client {
    margin: 0rem 0 4rem 0;
  }
  #about {
    p.content {
      padding: 15px 0 80px 0;
    }
    h4 p {
      line-height: 65px;
      font-size: 45px !important;
    }
    .about-tab-button .tab-buttons .ech-btn.nav-item {
      width: auto !important;
      a {
        border-radius: 12px;
        width: 300px;
        height: 90px;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .about-banner {
      padding: 5em 0;
    }
  }

  #weoffer {
    padding: 18px 0 145px 0 !important;
    h4 {
      margin: 40px 0 0 0;
      p {
        line-height: 65px;
        font-size: 45px !important;
      }
    }
    p.content {
      margin: 15px 0 0 0;
    }
    .offer-card-plane,
    .offer-card-yea {
      min-height: 300px;
      padding: 50px;
      h4.head-medium {
        font-size: 23px !important;
        margin: 50px 0px 5px 0px !important;
      }
      p.content-hash {
        font-style: 16px;
        line-height: 30px;
      }
    }
  }

  #solution {
    padding: 0rem 0 5rem 0;
    h4 {
      margin: 40px 0 30px 0;
      p {
        line-height: 65px;
        font-size: 45px !important;
      }
    }
    .sol-data {
      top: 49%;
      p.content {
        margin: 15px 0 0 0;
      }
      h4 p {
        font-size: 26px !important;
      }
    }
    .machine-blk .mach-sec {
      background-size: 55%;
    }
    .field-block {
      .card-item {
        padding: 15px 30px;
      }
      h5 {
        font-size: 20px !important;
      }
      .item-one {
        right: 15rem !important;
        top: 11rem !important;
        img {
          width: 300px !important;
          right: 30rem !important;
        }
        .card-item {
          right: -10rem !important;
        }
      }
      .item-two {
        right: 15rem !important;
        top: 21rem !important;
        img {
          width: 300px !important;
          right: 30rem !important;
        }
        .card-item {
          right: -10rem !important;
        }
      }
      .item-three {
        right: 15rem !important;
        top: 29rem !important;
        img {
          width: 300px !important;
          right: 30rem !important;
        }
        .card-item {
          right: -10rem !important;
        }
      }
      .item-four {
        right: 15rem !important;
        top: 35rem !important;
        img {
          width: 300px !important;
          right: 30rem !important;
        }
        .card-item {
          right: -10rem !important;
        }
      }
    }
  }

  #cofort {
    .lf-blk {
      padding-left: 0px;
      .icon {
        margin: 45px 0 !important;
        padding-top: 5em !important;
        img {
          width: 83px !important;
          margin: 0 12px 0 0px !important;
        }
      }
      h4.bold-hash-head {
        font-size: 77px !important;
        opacity: 0.17 !important;
        margin: 20px 0 20px 0 !important;
      }
      h4 p {
        font-size: 27px !important;
        font-weight: 600 !important;
        margin: 50px 0 !important;
      }
      img.download {
        width: 82% !important;
        margin: 10px -10px !important;
      }
    }
  }

  section#customer {
    padding: 10rem 0 11em 0 !important;
    h4 p {
      line-height: 51px;
      font-size: 44px;
    }
    p.content {
      margin: 27px 0 18px 0;
    }
    .customer-view {
      padding: 5rem 3rem;
      border-radius: 25px;
      .ech-count {
        h4 {
          font-size: 47px;
        }
        p {
          font-size: 18px;
          margin-top: 32px;
        }
      }
    }
  }

  #partner {
    padding: 8rem 0 8rem 0;
    h4 p {
      line-height: 48px;
      font-size: 43px;
    }
    p.content,
    .content {
      line-height: 32px;
    }
    .ech-part-btn span {
      padding: 30px 0;
      width: 400px;
      box-shadow: 0px 33px 39px #0000000f;
      font-size: 22px;
      font-weight: 500;
      img {
        width: 35px;
      }
    }
    img.dottedimg {
      height: 27rem;
      bottom: -80px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  section#weoffer .ipadrow {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  #about {
    .about-banner h3 {
      font-size: 22px;
    }

    .about-tab-button {
      .tab-buttons .ech-btn.nav-item {
        height: 50px !important;
      }
      .ech-btn.nav-item a {
        justify-content: space-between !important;
        width: 70% !important;
        margin: 0 auto;
      }
    }
  }
  #solution {
    h4 p {
      font-size: 21px !important;
    }
    .machine-blk {
      img {
        width: 38% !important;
      }
      .field-block {
        display: none !important;
      }
    }
  }

  .partner-wrapper .nav-item {
    width: 100% !important;
    a {
      padding: 15px 0 !important;
    }
  }
  .partner-service-tab {
    overflow-y: hidden !important;
    overflow-x: scroll !important;
  }
  #solution-tabpane-two {
    .partner-service-tab.nav.nav-pills .nav-item {
      min-width: 25% !important;
    }
  }
  .icons-no-slider {
    display: flex !important;
    flex-flow: wrap !important;
    img {
      width: 30% !important;
      margin: 15px 0 0 0;
    }
  }
  .offer-slider {
    .slick-track {
      margin: 0 -20px !important;
    }
  }
  #cofort .rg-blk .mobile-pic {
    animation: none !important;
  }
  .footer-main .ip-field {
    width: 70%;
    input {
      font-size: 14px;
      padding: 25px 15px;
    }
  }
  .news-letter {
    border-radius: 70px;
    min-height: 270px !important;
  }
  button#submit {
    background-image: linear-gradient(
      to right,
      #ec3b8d,
      #fc3773,
      #ff4057,
      #ff5137,
      #fe6703
    );
    font-size: 15px;
    color: #fff;
    height: 55px;
  }
  form#newsletter {
    display: flex;
    flex-flow: wrap;
    .form-group {
      margin: 0px;
      width: 100%;
    }
    .send-btn {
      position: relative;
      right: 0;
      width: 100%;
    }
  }
}

@keyframes move-left-to-right {
  0% {
    transform: translateX(5%);
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: translateX(15%);
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.4);
  }
  100% {
    transform: translateX(5%);
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
  }
}

@keyframes move-right-to-left {
  0% {
    transform: translateX(-5%);
    box-shadow: -3px 5px 5px rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: translateX(-15%);
    box-shadow: -3px 5px 5px rgba(0, 0, 0, 0.4);
  }
  100% {
    transform: translateX(-5%);
    box-shadow: -3px 5px 5px rgba(0, 0, 0, 0.2);
  }
}
