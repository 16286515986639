@import "../../_theme/variable.scss";
@import "../../_theme/flex.scss";

.partner-wrapper {
  .tabData {
    padding-top: 3em;
  }
  .head-medium-normal {
    min-height: 33px;
  }
  .sol-tab-header {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    border-radius: 5px;
    background: none;
    border: 1px solid $border !important;
    color: #1a1e23;
  }
  .nav-item {
    text-align: center;
    width: 20%;
    a {
      font-size: 12px;
      color: $content;
    }
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-image: $gradient-color;
    color: $white;
    padding: 15px 0;
    box-shadow: 6px 7px 32px #fe670347;
    opacity: 1;
  }
}

.capital {
  text-transform: uppercase;
}

.padd-top-3 {
  padding-top: 3rem;
}

#solution-tabpane-three {
  .data-card {
    .ech-item {
      min-height: 280px !important;
    }
  }
}
#solution-tabpane-four {
  .data-card {
    .ech-item {
      min-height: 335px !important;
      border-radius: 16px;
    }
  }
}
.data-card {
  .ech-item {
    position: relative;
    border: 2px solid transparent;
    border-radius: 14px;
    background: #fff;
    background-clip: padding-box;
    padding: 30px;
    min-height: 280px;
    &:after {
      position: absolute;
      top: -2px; 
      bottom: -2px;
      left: -2px; right: -2px;
      background-image: linear-gradient(to right top, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2);
      content: '';
      z-index: -1;
      border-radius: 18px;
    }
    &:hover {
      &:after {
        background-image: linear-gradient(to right, #ed0071, #f22d82, #f74392, #fb56a2, #ff67b1);
        box-shadow: 0px 18px 19px #0000000a;
      }
    }
    img.ico-img {
      background: linear-gradient(
        to right,
        #ec3b8d,
        #fc3773,
        #ff4057,
        #ff5137,
        #fe6703
      );
      padding: 10px;
      height: 45px;
      width: 45px;
      border-radius: 50%;
    }
    &:hover {
      cursor: pointer;
      border: 1px solid $magenta;
    }
  }
}

.case-accordian {
  .accordion {
    .card {
      margin-bottom: 18px;
      border: 1px solid $border;
      border-radius: 10px;
      transition: all 0.3s ease-in;
      .card-header {
        @include flexbox();
        @include justify-content(space-between);
        padding: 5px 20px;
        background: $white;
        span.accord-head {
          font-size: 12px;
          line-height: normal !important;
          @include flexbox();
          @include align-items(center);
        }
        .icon {
          font-size: 14px;
          font-weight: 600;
          color: #fe6703;
          display: flex;
          align-items: center;
        }
      }
      &:first-child {
        display: none;
      }
      .card-body {
        font-size: 14px;
        padding: 0 3.25rem 1.25rem 3.25rem;
      }
    }
    .active.card {
      border-radius: 13px;
      border: 1px solid #fe6703;
      padding: 15px 0px;
      box-shadow: -3px 0px 16px #a0979714;
      .card-header {
        border: none !important;
        border-bottom: 1px solid transparent !important;
        span.accord-head {
          font-weight: 600;
        }
        .icon {
          color: #fe6703;
        }
      }
    }
  }
}

img.enable {
  display: block;
}
img.enable {
  display: none;
}
.partner-service-tab.nav.nav-pills {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid $border;
  .nav-item {
    width: 10% !important;
    a {
      min-width: 10% !important;
      padding: 15px;
      height: 85px;
    }
  }
  .img-sec {
    height: 35px;
    width: 35px;
    display: block;
    margin: auto;
  }
  span {
    font-size: 11px !important;
    font-family: "poppins", regular !important;
    line-height: 12px !important;
    display: block;
  }
  .nav-item {
    &:nth-child(1) {
      img {
        width: 28px;
        height: 28px;
      }
    }
    &:nth-child(2) {
      img {
        width: 28px;
        height: 28px;
      }
    }
    &:nth-child(3) {
      img {
        width: 28px;
        height: 28px;
      }
    }
    &:nth-child(4) {
      img {
        width: 28px;
        height: 28px;
      }
    }
    &:nth-child(5) {
      img {
        width: 28px;
        height: 28px;
      }
    }
    &:nth-child(6) {
      img {
        width: 28px;
        height: 28px;
      }
    }
    &:nth-child(7) {
      img {
        width: auto !important;
        height: auto !important;
      }
    }
    &:nth-child(8) {
      img {
        width: 28px;
        height: 28px;
      }
    }
    &:nth-child(9) {
      img {
        width: 28px;
        height: 28px;
      }
    }
    &:nth-child(10) {
      img {
        width: 28px;
        height: 28px;
      }
    }
  }
}

.icons-no-slider {
  img.one {
    width: 10%;
    height: auto;
  }
  img.two {
    width: 8%;
    height: auto;
  }
  img.three {
    width: 7%;
    height: auto;
  }
  img.four {
    width: 8%;
    height: auto;
  }
  img.five {
    width: 6%;
    height: auto;
  }
  img.six {
    width: 8%;
    height: auto;
  }
  img.seven {
    width: 8%;
    height: auto;
  }
  img.enter-one {
    width: 6%;
    height: auto;
  }
  img.enter-two {
    width: 6%;
    height: auto;
  }
  img.enter-three {
    width: 9%;
    height: auto;
  }
  img.enter-four {
    width: 6%;
    height: auto;
  }
  img.money-one {
    width: 10%;
    height: auto;
  }
  img.money-two {
    width: 9%;
    height: auto;
  }
  img.money-three {
    width: 6%;
    height: auto;
  }
  img.money-four {
    width: 6%;
    height: auto;
  }
}

.service-reg-block {
  background-image: linear-gradient(
    to right,
    #ffffff,
    #eee2fb,
    #e7c3f0,
    #e7a1dd,
    #ea7bc0,
    #f366aa,
    #f95090,
    #fc3773,
    #ff3c5e,
    #ff4847,
    #ff572e,
    #fe6703
  );
  height: 15rem;
  .butt-block {
    transform: translate(0%, -50%);
    position: absolute;
    top: 50%;
    color: #fff;
    h3 {
      color: $content;
      font-weight: 700;
      opacity: 1;
    }
  }
}

form.form-block {
  background: $white;
  box-shadow: 6px 13px 48px #0000001f;
  border-radius: 18px;
  opacity: 1;
  padding: 3rem;
  .mbme-form textarea {
    min-height: 215px !important;
  }
  select {
    font-size: 12px;
    background: #f4f5f642 0% 0% no-repeat padding-box !important;
    border: 1px solid #70707030 !important;
    border-radius: 8px !important;
    min-height: 40px;
  }
  h4.head-medium-normal {
    min-height: 35px;
    font-size: 23px;
    color: $content;
  }
  .content {
    min-height: 55px !important;
  }
  .send-button {
    position: absolute;
    right: 45px;
    bottom: 0px;
    button {
      border: none;
      &:focus {
        outline: none;
      }
    }
  }
  .mbme-form {
    .form-group {
      margin-bottom: 0rem;
  }
  }
}


/* Hd Media Query */
@media only screen and (min-width: 1550px)  {  
  .partner-wrapper {
    .nav-link.active, .solution-wrapper .nav-pills .show > .nav-link {
      padding: 18px 15px !important;
    }
    .nav-item a {
      font-size: 16px;
    }
    .partner-service-tab.nav.nav-pills .nav-item a { 
      height: 105px;
    }
    .partner-service-tab.nav.nav-pills span {
      font-size: 14px !important;
      line-height: 16px !important;
    }
    .nav-item {
      .img-sec {
        height: 54px;
        width: 60px;
      }
      img {
        height: 35px;
        width: 35px;
      }
      &:nth-child(1) {
        img {
          width: 35px;
          height: 35px;
        }
      }
      &:nth-child(2) {
        img {
          width: 33px;
          height: 33px;
        }
      }
      &:nth-child(3) {
        img {
          width: 28px;
          height: 28px;
        }
      }
      &:nth-child(4) {
        img {
          width: 32px;
          height: 32px;
        }
      }
      &:nth-child(5) {
        img {
          width: 33px;
          height: 33px;
        }
      }
      &:nth-child(6) {
        img {
          width: 35px;
          height: 35px;
        }
      }
      &:nth-child(7) {
        img {
          width: auto !important;
          height: auto !important;
        }
      }
      &:nth-child(8) {
        img {
          width: 40px;
          height: 40px;
        }
      }
      &:nth-child(9) {
        img {
          width: 35px;
          height: 35px;
        }
      }
      &:nth-child(10) {
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
    .data-card .ech-item img.ico-img {
      height: 55px;
      width: 55px;
    }
    .head-medium-normal {
      line-height: 29px;
      font-weight: 600;
    }
    .case-accordian {
      .accordion .card .card-header span.accord-head {
        font-size: 16px !important;
        padding: 15px 30px;
        background: #ffffff;
        .icon {
          font-size: 30px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  form.form-block .mbme-form textarea {
    height: 250px !important;
    padding: 25px 30px;
  }
}


@media only screen and (min-width: 320px) and (max-width: 768px) {
  .data-card .ech-item {
    min-height: 150px;
    .styled-ul.kiosk-features { height: auto; }
  }
  .partner-wrapper .head-medium-normal {
    padding: 20px 0;
}
  #solution-tabpane-two {
    .tabData .category {
      flex-flow: wrap;
      .each-block {
       width: 49%;
      }
    }
    .kiosk-features {
      height: auto !important;
    }
  }
  .inner-tab-data {
    .icons-no-slider img {
      width: 14% !important;
      margin: 15px 0 0 0;
  }
  }
  #solution-tabpane-four {
    .data-card .ech-item { min-height: 150px !important; }
    .styled-ul.kiosk-features { height: auto; }
  }
  .partner-wrapper .head-medium-normal {
    padding: 20px 0;
  }

}

.icons-no-slider > img {
  width: 5% !important;
  height: auto;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  #solution-tabpane-two .partner-service-tab.nav.nav-pills .nav-item {
    min-width: 25% !important;
  }
  .partner-service-tab {
    overflow-x: scroll !important;
    padding-bottom: 5px;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
}