.client-image {
    _height: 60px;
    max-width: 100px;
    width: 100%;
    max-height: 80px;
    margin-left: 20px;
}

.slick-slider .slick-track {
    display: flex;
    align-items: center;
}